import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, message} from 'antd';
import queryString from 'query-string';
import {req} from 'rev.sdk.js/Utils/ApiUtil';
import jwtDecode from 'jwt-decode';
import * as AppActions from '../../AppActions';
import Config from '../../../data.json';
import * as User from 'rev.sdk.js/Actions/User';
import {Link} from 'gatsby';

async function registerConfirm({password, access_token, store_id}) {
  // let ApiUtil the path (/user/ -> /store/)
  const resp = await req(`${Config.apiHost}/user/register/confirm`, {
    method: 'POST',
    data: {password, access_token, store_id},
  });
  // Gtag('event', 'sign_up', {method: 'default'});
  return resp;
}

function ConfirmPage(props) {
  const [requestResult, setRequestResult] = React.useState(null);
  const {access_token} = queryString.parse(props.location.search);

  const decodedData = React.useMemo(() => {
    if (access_token) {
      try {
        let decoded = jwtDecode(access_token);
        return {
          ...decoded,
          username: decoded.sub,
        };
      } catch (err) {
        console.warn('jwt decode error', err);
      }
    }
    return null;
  }, [access_token]);

  const onFinish = async (values) => {
    const {password1, password2, store_id, username} = values;

    if (password1 !== password2) {
      alert('密碼不相符, 請重新輸入');
      return;
    }

    try {
      AppActions.setLoading(true);
      await registerConfirm({password: password1, access_token, store_id});
      setRequestResult(true);
      message.success('註冊完成！');
      AppActions.setLoading(true, {message: '自動登入中...'});
      await User.login({username: username, password: password1}, true);
      await AppActions.navigate('/admin/');
    } catch (ex) {
      if (ex.status === 400 && ex.response?.error === 'store_existed_error') {
        message.warn(`店家名稱 ${store_id} 已被註冊，請使用其他店家名稱`);
      }

      if (ex.status === 400 && ex.response?.error === 'jwt_decode_error') {
        message.warn(`信件註冊連結已失效，請重新註冊`);
      }
      console.log('EX', ex);
      setRequestResult(false);
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const disableInput = requestResult === true;

  return (
    <Wrapper>
      <div className="content">
        <h2 style={{marginBottom: 10}}>完成註冊流程</h2>
        <p>請填寫您的店家名稱及登入密碼</p>

        {requestResult === true && (
          <div
            style={{
              padding: 15,
              borderRadius: 6,
              backgroundColor: '#eee',
              marginBottom: 15,
            }}>
            <h3 style={{marginBottom: 10}}>成功</h3>
            <p style={{marginBottom: 15}}>
              您的帳戶已經建立, 現在可以用新密碼重新登入摟!
            </p>
            <Button onClick={() => AppActions.navigate('/admin')}>
              立即前往
            </Button>
          </div>
        )}

        {requestResult === false && (
          <div
            style={{
              padding: 15,
              borderRadius: 6,
              backgroundColor: '#eee',
              marginBottom: 15,
            }}>
            <h3 style={{marginBottom: 10}}>無法創建店家</h3>
            <p>請重新嘗試一次, 或是更換店家名稱</p>
            <p>
              若連結已失效，請
              <Link to={'/store/register/request/'}>點此重新註冊</Link>
            </p>
          </div>
        )}

        {requestResult !== true && (
          <Form
            layout="vertical"
            name="register"
            initialValues={{
              username: decodedData?.username || '',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              label="店家名稱"
              name="store_id"
              rules={[
                {
                  required: true,
                  message: '請輸入您的自訂店家名稱(英數字混合)',
                },
                {
                  pattern: /^[0-9A-Za-z_]+$/,
                  message: '店家名稱必須為英數字',
                },
              ]}>
              <Input
                disabled={disableInput}
                placeholder="請輸入英數字組合不包含特殊符號"
              />
            </Form.Item>

            {decodedData && (
              <Form.Item label="登入帳號" name="username">
                <Input disabled={true} />
              </Form.Item>
            )}

            <Form.Item
              label="密碼"
              name="password1"
              rules={[
                {
                  required: true,
                  message: '請輸入密碼!',
                },
                {
                  min: 8,
                  message: '密碼長度至少須為 8 碼',
                },
              ]}>
              <Input.Password disabled={disableInput} />
            </Form.Item>

            <Form.Item
              label="再次輸入密碼"
              name="password2"
              dependencies={['password1']}
              rules={[
                {
                  required: true,
                  message: '請再次輸入密碼!',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('密碼不一致'));
                  },
                }),
              ]}>
              <Input.Password disabled={disableInput} />
            </Form.Item>

            <Form.Item style={{textAlign: 'right'}}>
              <Button type="primary" htmlType="submit">
                送出
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  padding-bottom: 60px;
  background-color: #eee;
  min-height: 100vh;

  & > .content {
    max-width: 600px;
    margin: 20px auto;
    padding: var(--basePadding);

    & > p {
      margin: 10px 0px 40px;
    }
  }
`;

export default ConfirmPage;
